const statuses = new Map();

statuses.set('Request received', {
  definitionKey: 'vastestate',
  label: 'Inschrijving ontvangen',
  chartColor: '#ff851b'
});

statuses.set('Request rejected', {
  definitionKey: 'vastestate',
  label: 'Inschrijving afgewezen',
  chartColor: '#FF0000'
});

statuses.set('waiting for extra info from customer', {
  definitionKey: 'vastestate',
  label: 'Meer informatie uitgevraagd',
  chartColor: '#ff851b'
});

statuses.set('Request recevied with extra info', {
  definitionKey: 'vastestate',
  label: 'Opstellen van een huurvoorstel',
  chartColor: '#ff851b'
});

statuses.set('Waiting for approvar', {
  definitionKey: 'vastestate',
  label: 'Wachten op een akkoord controle verantwoordelijk',
  chartColor: '#b10dc9'
});

statuses.set('Request rejected by approvar and need modifcation', {
  definitionKey: 'vastestate',
  label: 'Voorstel afgekeurd door controle verantwoordelijk',
  chartColor: '#b10dc9'
});

statuses.set('waiting for proposal approve from customer', {
  definitionKey: 'vastestate',
  label: 'Wachten op een akkoord huurder',
  chartColor: '#39cccc'
});

statuses.set('Extra info required by customer', {
  definitionKey: 'vastestate',
  label: 'Vragen van huurder',
  chartColor: '#39cccc'
});

statuses.set('Request rejected by customer and need modification', {
  definitionKey: 'vastestate',
  label: 'Voorstel afgekeurd door huurder',
  chartColor: '#39cccc'
});

statuses.set('Proposal need modification', {
  definitionKey: 'vastestate',
  label: 'Aanpassingen aan het huurvoorstel',
  chartColor: '#ff851b'
});

statuses.set('waiting for proposal approve from customer with extra info', {
  definitionKey: 'vastestate',
  label: 'Wachten op een akkoord huurder met aanpassingen',
  chartColor: '#39cccc'
});

statuses.set('Process finished customer did not respond', {
  definitionKey: 'vastestate',
  label: 'Geen reactie van huurder op voorstel na herinnering',
  chartColor: '#FF0000'
});

statuses.set('Waiting for contract upload', {
  definitionKey: 'vastestate',
  label: 'Contract opstellen',
  chartColor: '#ff851b'
});

statuses.set('Idproof auth link sent', {
  definitionKey: 'vastestate',
  label: 'IDProof verificatie uitgestuurd',
  chartColor: '#39cccc'
});

statuses.set('Process finished customer did not respond for idproof auth', {
  definitionKey: 'vastestate',
  label: 'Geen reactie van huurder op IDProof na herinnering',
  chartColor: '#FF0000'
});

statuses.set('Signhost mail sent', {
  definitionKey: 'vastestate',
  label: 'Ondertekenverzoek uitgestuurd',
  chartColor: '#39cccc'
});

statuses.set('proposal submitted', {
  definitionKey: 'vastestate',
  label: 'Contract retour ontvangen',
  chartColor: '#2ecc40'
});

export const getProcessDefKeyByStatus = statusName => {
  const foundStatus = statuses.get(statusName);
  if (!foundStatus) {
    return null;
  }

  return foundStatus.definitionKey;
};

export const getLabelByStatus = statusName => {
  const foundStatus = statuses.get(statusName);
  if (!foundStatus) {
    return null;
  }

  return foundStatus.label;
};

export const getStatuses = () => {
  return new Map(statuses);
};
