import {
  GET_PROCESSES_BY_KEY_AND_TENANT,
  GET_PROCESSES_BY_KEY_AND_TENANT_SUCCESS,
  GET_PROCESS_DETAILS,
  GET_PROCESS_DETAILS_SUCCESS,
  GET_PROCESSES_TABLE_DATA,
  GET_PROCESSES_TABLE_DATA_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
  list: null,
  details: null,
  table: {
    columns: [
      {
        id: 'refNumber',
        align: 'left',
        label: 'Referentie'
      },
      {
        id: 'statusTime',
        align: 'left',
        label: 'Tijd laatste activiteit'
      },
      {
        id: 'unitnummers',
        align: 'left',
        label: 'Adres'
      },
      {
        id: 'gegevens_postcode',
        align: 'left',
        label: 'Postcode'
      },
      {
        id: 'plaats_vestiging',
        align: 'left',
        label: 'Plaats'
      },
      {
        id: 'specifieke_toelichting',
        align: 'left',
        label: 'Specifieke toevoeging'
      },
      {
        id: 'firstCustomerFullName',
        align: 'left',
        label: 'Naam'
      },
      {
        id: 'tekeningsbevoegde_persoonen_0_email',
        align: 'left',
        label: 'Hoofd E-mailadres'
      }
    ],
    data: [],
    order: '',
    orderBy: '',
    page: 0,
    rowsPerPage: 25,
    totalCount: 0,
    searchQuery: '',
    loading: false
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROCESSES_BY_KEY_AND_TENANT: {
      return {
        ...state,
        list: null
      };
    }

    case GET_PROCESSES_BY_KEY_AND_TENANT_SUCCESS: {
      return {
        ...state,
        list: action.payload
      };
    }

    case GET_PROCESS_DETAILS: {
      return {
        ...state,
        details: null
      };
    }

    case GET_PROCESS_DETAILS_SUCCESS: {
      return {
        ...state,
        details: action.payload
      };
    }

    case GET_PROCESSES_TABLE_DATA: {
      return {
        ...state,
        table: {
          ...state.table,
          loading: true
        }
      };
    }

    case GET_PROCESSES_TABLE_DATA_SUCCESS: {
      return {
        ...state,
        table: {
          ...state.table,
          ...action.payload,
          loading: false
        }
      };
    }

    default:
      return state;
  }
};
