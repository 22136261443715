import { all } from 'redux-saga/effects';

import AuthSagas from './Auth';
import TasksSagas from './Tasks';
import ProcessesSagas from './Processes';
import DashboardSagas from './Dashboard';
import CandidateTasksSaga from './CandidateTasks';

export default function* rootSaga(getState) {
  yield all([
    AuthSagas(),
    TasksSagas(),
    ProcessesSagas(),
    DashboardSagas(),
    CandidateTasksSaga()
  ]);
}
