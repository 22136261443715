import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { GET_TASKS_BY_GROUPS_CANDIDATE } from 'constants/ActionTypes';
import { getTasksByGroupsCandidateSuccess, getAPI } from 'actions';
import { showMessage } from 'actions/NotificationMessage';

const getTasksByCandidateGroups = async (candidateGroups, processInstanceBusinessKey, tenantId) => {
  const resp = await getAPI('task', { candidateGroups: candidateGroups, processInstanceBusinessKey: processInstanceBusinessKey, tenantId: tenantId});
  return resp;
};

function* getTasksByCandidateGroup({ payload: {candidateGroups, processInstanceBusinessKey, tenantId} }) {
  try {
    const tasks = yield call(getTasksByCandidateGroups, candidateGroups, processInstanceBusinessKey, tenantId);
    yield put(getTasksByGroupsCandidateSuccess(tasks));
  } catch (error) {
    yield put(
      showMessage('Something is wrong with server. Please try again later.')
    );
  }
}

export function* getTasks() {
  yield takeEvery(GET_TASKS_BY_GROUPS_CANDIDATE, getTasksByCandidateGroup);
}

export default function* rootSaga() {
  yield all([fork(getTasks)]);
}
