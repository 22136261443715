import {
  GET_TASKS_BY_GROUP_NAME,
  GET_TASKS_BY_GROUP_NAME_SUCCESS,
  GET_TASKS_BY_GROUPS_CANDIDATE,
  GET_TASKS_BY_GROUPS_CANDIDATE_SUCCESS
} from 'constants/ActionTypes';

export const getTasksByGroupName = groupNames => ({
  type: GET_TASKS_BY_GROUP_NAME,
  payload: groupNames
});

export const getTasksByGroupNameSuccess = tasks => ({
  type: GET_TASKS_BY_GROUP_NAME_SUCCESS,
  payload: tasks
});

export const getTasksByGroupsCandidate = (candidateGroups, processInstanceBusinessKey, tenantId) => ({
  type: GET_TASKS_BY_GROUPS_CANDIDATE,
  payload: {candidateGroups, processInstanceBusinessKey, tenantId}
});

export const getTasksByGroupsCandidateSuccess = tasks => ({
  type: GET_TASKS_BY_GROUPS_CANDIDATE_SUCCESS,
  payload: tasks
});


