import {
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER
} from 'constants/ActionTypes';

export const userSignIn = user => ({
  type: SIGNIN_USER,
  payload: user
});

export const userSignOut = () => ({
  type: SIGNOUT_USER
});

export const userSignInSuccess = authUser => ({
  type: SIGNIN_USER_SUCCESS,
  payload: authUser
});

export const setInitUrl = url => ({
  type: INIT_URL,
  payload: url
});

export const showAuthLoader = () => ({
  type: ON_SHOW_LOADER
});

export const hideAuthLoader = () => ({
  type: ON_HIDE_LOADER
});
