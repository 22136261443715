import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { BELOW_THE_HEADER, COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from 'constants/ActionTypes';
import { toggleCollapsedNav } from 'actions/Setting';

class Header extends React.Component {
  onToggleCollapsedNav = e => {
    const { navCollapsed, toggleCollapsedNav } = this.props;
    const val = !navCollapsed;
    toggleCollapsedNav(val);
  };

  render() {
    const { drawerType, navigationStyle, horizontalNavPosition } = this.props;
    /* eslint-disable no-nested-ternary, indent */
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'd-block d-xl-none'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? 'd-block'
      : 'd-none';
    /* eslint-enable no-nested-ternary, indent */

    console.log(process.env.REACT_APP_EXTERNAL_FORM_URL.indexOf('acc') === -1);

    return (
      <AppBar
        className={`app-main-header no-shadow ${
          navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER
            ? 'app-main-header-top'
            : ''
        }`}
      >
        <Toolbar className="app-toolbar" disableGutters={false}>
          {navigationStyle === HORIZONTAL_NAVIGATION ? (
            <div
              className="d-block d-md-none pointer mr-3"
              onClick={this.onToggleCollapsedNav}
              role="button"
              tabIndex="-1"
            >
              <span className="jr-menu-icon">
                <span className="menu-icon" />
              </span>
            </div>
          ) : (
            <IconButton
              className={`jr-menu-icon mr-3 ${drawerStyle}`}
              aria-label="Menu"
              onClick={this.onToggleCollapsedNav}
            >
              <span className="menu-icon" />
            </IconButton>
          )}
          { process.env.REACT_APP_EXTERNAL_FORM_URL.indexOf('acc') === -1 ? null : <span className="environment-message">Acceptance Environment</span>}
          <Link className="app-logo d-sm-block" to="/">
            <img src={require('assets/images/logo.png')} alt="MyCubes" title="MyCubes" />
          </Link>
          { process.env.REACT_APP_EXTERNAL_FORM_URL.indexOf('acc') === -1 ? null : <span className="environment-message">Acceptance Environment</span>}
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return {
    drawerType,
    navigationStyle,
    horizontalNavPosition
  };
};

export default withRouter(connect(mapStateToProps, { toggleCollapsedNav })(Header));
