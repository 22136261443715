import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { GET_TASKS_BY_GROUP_NAME } from 'constants/ActionTypes';
import { getTasksByGroupNameSuccess, getAPI } from 'actions';
import { showMessage } from 'actions/NotificationMessage';

const getTasksByGroupNames = async groupNames => {
  const resp = await getAPI('task', { candidateGroups: groupNames });
  return resp;
};

function* getTasksByGroupName({ payload: groupNames }) {
  try {
    const tasks = yield call(getTasksByGroupNames, groupNames);
    yield put(getTasksByGroupNameSuccess(tasks));
  } catch (error) {
    yield put(
      showMessage('Something is wrong with server. Please try again later.')
    );
  }
}

export function* getTasks() {
  yield takeEvery(GET_TASKS_BY_GROUP_NAME, getTasksByGroupName);
}

export default function* rootSaga() {
  yield all([fork(getTasks)]);
}
