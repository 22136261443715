import {
  HIDE_MESSAGE,
  SHOW_MESSAGE
} from 'constants/ActionTypes';

const INIT_STATE = {
  alertMessage: '',
  showMessage: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true
      };
    }

    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false
      };
    }

    default:
      return state;
  }
};
