import {
  GET_TASKS_BY_GROUP_NAME_SUCCESS,
  GET_TASKS_BY_GROUP_NAME,
  GET_TASKS_BY_GROUPS_CANDIDATE,
  GET_TASKS_BY_GROUPS_CANDIDATE_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
  list: null,
  candidateList: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TASKS_BY_GROUP_NAME: {
      return {
        ...state,
        list: null
      };
    }

    case GET_TASKS_BY_GROUP_NAME_SUCCESS: {
      return {
        ...state,
        list: action.payload
      };
    }

    case GET_TASKS_BY_GROUPS_CANDIDATE: {
      return {
        ...state,
        condidateList: null
      }
    }

    case GET_TASKS_BY_GROUPS_CANDIDATE_SUCCESS: {
      return {
        ...state,
        candidateList: action.payload
      }
    }

    default:
      return state;
  }
};
