import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Auth from './Auth';
import NotificationMessage from './NotificationMessage';
import Settings from './Settings';
import Tasks from './Tasks';
import Processes from './Processes';
import Dashboard from './Dashboard';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: Auth,
    notificationMessage: NotificationMessage,
    settings: Settings,
    tasks: Tasks,
    processes: Processes,
    dashboard: Dashboard
  });
