import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import {connect} from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { userSignOut } from 'actions/Auth';

class UserInfo extends React.Component {

  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = (event) => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  handleRequestClose = () => {
    this.setState({open: false});
  };

  render() {
    const { anchorEl, open } = this.state;
    const { userSignOut, userName } = this.props;

    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <Avatar className="user-avatar"><h4 className="m-0 text-white">{userName[0].toUpperCase()}</h4></Avatar>
        <div className="user-detail">
          <h4 className="user-name" onClick={this.handleClick}>
            {userName}
            &nbsp;
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0
            }
          }}>
          <MenuItem onClick={() => {
            this.handleRequestClose();
            userSignOut();
          }}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />
            Uitloggen
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({auth: { authUser }}) => ({
  userName: authUser.authenticatedUser
});

export default connect(mapStateToProps, { userSignOut })(UserInfo);
