import {
  GET_PROCESSES_BY_KEY_AND_TENANT,
  GET_PROCESSES_BY_KEY_AND_TENANT_SUCCESS,
  GET_PROCESS_DETAILS,
  GET_PROCESS_DETAILS_SUCCESS,
  GET_PROCESSES_TABLE_DATA,
  GET_PROCESSES_TABLE_DATA_SUCCESS
} from 'constants/ActionTypes';

export const getProcessesByKeyAndTenant = ({
  processDefinitionKey,
  tenantIdIn
}) => ({
  type: GET_PROCESSES_BY_KEY_AND_TENANT,
  payload: {
    processDefinitionKey,
    tenantIdIn,
    sortBy: 'businessKey',
    sortOrder: 'asc'
  }
});

export const getProcessesByKeyAndTenantSuccess = processes => ({
  type: GET_PROCESSES_BY_KEY_AND_TENANT_SUCCESS,
  payload: processes
});

export const getProcessDetails = processInstanceId => ({
  type: GET_PROCESS_DETAILS,
  payload: processInstanceId
});

export const getProcessDetailsSuccess = processDetails => ({
  type: GET_PROCESS_DETAILS_SUCCESS,
  payload: processDetails
});

export const getProcessesTableData = settings => ({
  type: GET_PROCESSES_TABLE_DATA,
  payload: settings
});

export const getProcessesTableDataSuccess = tableData => ({
  type: GET_PROCESSES_TABLE_DATA_SUCCESS,
  payload: tableData
});
