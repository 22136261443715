import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import "assets/vendors/style";
import MainApp from "app/index";
import asyncComponent from "util/asyncComponent";
import logiusTheme from "./themes/logiusTheme";

import { setInitUrl } from "../actions/Auth";
import { RestrictedRoute } from "../app/routes";

class App extends Component {
  componentDidMount() {
    const { initURL, setInitUrl, history } = this.props;
    // eslint-disable-next-line no-underscore-dangle
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === "") {
      setInitUrl(history.location.pathname);
    }
  }

  render() {
    const { match, location, authUser, initURL } = this.props;

    if (location.pathname === "/") {
      if (authUser === null) {
        return <Redirect to="/signin" />;
      }
      if (initURL === "" || initURL === "/" || initURL === "/signin") {
        return <Redirect to="/app/dashboard" />;
      }
      return <Redirect to={initURL} />;
    }

    return (
      <MuiThemeProvider theme={createMuiTheme(logiusTheme)}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className="app-main">
            <Switch>
              <RestrictedRoute
                path={`${match.url}app`}
                authUser={authUser}
                component={MainApp}
              />
              <Route
                path="/signin"
                component={asyncComponent(() => import("app/routes/LoginPage"))}
              />
              <Route
                component={asyncComponent(() => import("components/Error404"))}
              />
            </Switch>
          </div>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser, initURL } = auth;
  return {
    authUser,
    initURL
  };
};

export default connect(mapStateToProps, { setInitUrl })(App);
