import axios from 'axios';

export * from './Auth';
export * from './Dashboard';
export * from './Tasks';
export * from './Processes';
export * from './NotificationMessage';

const getAuthToken = () => {
  const userSession = sessionStorage.getItem('user');
  if (userSession) {
    return JSON.parse(sessionStorage.getItem('user')).token;
  }
  return null;
};

export const getAPI = async (url, data, token) => {
  try {
    const config = {
      headers: {
        Authorization: token || getAuthToken()
      }
    };

    if (data) {
      config.params = data;
    }

    const resp = await axios.get(`/api/${url}`, config);

    return resp.data;
  } catch (err) {
    if (err.response.status === 401) {
      sessionStorage.removeItem('user');
      window.location = '/';
      return null;
    }
    throw err;
  }
};

export const postAPI = async (url, data, token) => {
  try {
    const config = {
      headers: {
        Authorization: token || getAuthToken()
      }
    };

    const resp = await axios.post(`/api/${url}`, data, config);

    return resp.data;
  } catch (err) {
    if (err.response.status === 401) {
      sessionStorage.removeItem('user');
      window.location = '/';
      return null;
    }
    throw err;
  }
};

export const putAPI = async (url, data) => {
  try {
    const config = {
      headers: {
        Authorization: getAuthToken()
      }
    };

    const resp = await axios.put(`/api/${url}`, data, config);

    return resp.data;
  } catch (err) {
    if (err.response.status === 401) {
      sessionStorage.removeItem('user');
      window.location = '/';
      return null;
    }
    throw err;
  }
};
