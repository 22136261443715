import {
  GET_DASHBOARD_STATUSES,
  GET_DASHBOARD_STATUSES_SUCCESS
} from 'constants/ActionTypes';

export const getDashboardStatuses = () => ({
  type: GET_DASHBOARD_STATUSES
});

export const getDashboardStatusesSuccess = statusesInfo => ({
  type: GET_DASHBOARD_STATUSES_SUCCESS,
  payload: statusesInfo
});
