import {
  GET_DASHBOARD_STATUSES,
  GET_DASHBOARD_STATUSES_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
  statusesCount: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_STATUSES: {
      return {
        ...state,
        statusesCount: null
      };
    }

    case GET_DASHBOARD_STATUSES_SUCCESS: {
      return {
        ...state,
        statusesCount: action.payload
      };
    }

    default:
      return state;
  }
};
