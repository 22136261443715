import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const RestrictedRoute = ({component: Component, authUser, ...rest}) => (
  <Route
    {...rest}
    render={props => (
      authUser
        ? <Component {...props} />
        : (
          <Redirect
            to={{
              pathname: '/signin'
            }}
          />
        )
    )}
  />
);
