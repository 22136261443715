import {
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER
} from 'constants/ActionTypes';

const authUserSession = sessionStorage.getItem('user');

const INIT_STATE = {
  loader: false,
  initURL: '',
  authUser: authUserSession ? JSON.parse(authUserSession) : null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNIN_USER_SUCCESS: {
      sessionStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        loader: false,
        authUser: action.payload
      };
    }

    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      };
    }

    case SIGNOUT_USER: {
      sessionStorage.removeItem('user');
      return {
        ...state,
        authUser: null,
        initURL: '',
        loader: false
      };
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      };
    }

    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      };
    }

    default:
      return state;
  }
};
