export const protectRoute = (userGroups) => {
  let blockRouteFlag = false;

    for (let group of userGroups) {
      if (group.name === "evidos") {
        blockRouteFlag = true;
      }
    }

    return blockRouteFlag;
}